<template>
    <VFooter
        :style="lang === 'ar' ? 'direction:rtl' : 'direction:ltr'"
        width="100%"
        :color="theme === 'dark' ? '' : 'grey-lighten-5'"
        class="border border-b-0 mx-auto flex-column"
        style="width: 100%"
    >
        <v-container
            class="pa-1"
            :style="lang === 'ar' ? 'direction:rtl' : 'direction:ltr'"
        >
            <VRow justify="center">
                <!-- followUs start -->
                <VCol cols="12" md="3" sm="6" lg="3" xl="3" xxl="3">
                    <v-img
                        class="d-inline-block mx-0 pa-0"
                        :class="
                            theme === 'dark'
                                ? 'bg-white rounded-e-xl  pa-5 '
                                : ''
                        "
                        width="50"
                        rounded
                        :src="
                            require('../../assets/imgs/logo/original-logo.png')
                        "
                        lazy-src="https://picsum.photos/id/11/100/60"
                    >
                        <template v-slot:placeholder>
                            <div
                                class="d-flex align-center justify-center fill-height"
                            >
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    :size="50"
                                ></v-progress-circular>
                            </div>
                        </template>
                    </v-img>
                    <VCard
                        flat
                        :color="theme === 'dark' ? '' : 'grey-lighten-5'"
                    >
                        <VCardTitle
                            primary-title
                            class="text-center text-h6 ps-0"
                            :class="theme === 'dark' ? '' : 'text-green'"
                        >
                            {{ $t("projectName") }}
                        </VCardTitle>
                        <p
                            class="text-justify"
                            :class="
                                theme === 'dark' ? '' : 'text-grey-darken-1'
                            "
                        >
                            {{ $t("footer.companyPara") }}
                        </p>
                    </VCard>
                    <VCard
                        flat
                        class="mt-3"
                        :color="theme === 'dark' ? '' : 'transparent'"
                    >
                        <VCol class="text-center">
                            <a
                                target="_blank"
                                href="https://www.instagram.com/"
                            >
                                <v-icon
                                    class="me-2 text-h5"
                                    :color="theme === 'dark' ? '' : '#f4019a'"
                                    >mdi-instagram</v-icon
                                >
                                <v-tooltip activator="parent" location="bottom">
                                    {{ $t("contacts.instagram") }}
                                </v-tooltip>
                            </a>
                            <a target="_blank" href="https://www.facebook.com/">
                                <v-icon
                                    class="me-2 text-h5"
                                    :color="theme === 'dark' ? '' : '#0866ff'"
                                    >mdi-facebook</v-icon
                                >
                                <v-tooltip activator="parent" location="bottom">
                                    {{ $t("contacts.facebook") }}
                                </v-tooltip>
                            </a>
                            <a target="_blank" href="https://www.youtube.com/">
                                <v-icon
                                    class="me-2 text-h5"
                                    :color="theme === 'dark' ? '' : '#ff0000'"
                                    >mdi-youtube</v-icon
                                >
                                <v-tooltip activator="parent" location="bottom">
                                    {{ $t("contacts.youtube") }}
                                </v-tooltip>
                            </a>
                        </VCol>
                    </VCard>
                </VCol>
                <!-- followUs emd -->

                <!-- Quick links Start -->
                <VCol cols="12" md="3" sm="6" lg="3" xl="4" xxl="4">
                    <div class="mt-5">
                        <h3>{{ $t("quickLinks") }}</h3>
                    </div>
                    <v-divider class="mx-auto my-3" width="50%"></v-divider>
                    <v-list
                        class="mt-5"
                        bg-color="transparent"
                        active-class="bg-transparent"
                    >
                        <v-list-item
                            active-class="py-0 mb-1 "
                            v-for="link in links"
                            :key="link"
                            :to="link.to"
                            class="my-0 py-0"
                            :append-icon="lang === 'ar' ? link.icon : ''"
                            :prepend-icon="lang != 'ar' ? link.icon : ''"
                        >
                            <v-list-item-title class="text-start py-0">
                                <span class="text-body-2">
                                    <!-- <v-icon>{{ link.icon }}</v-icon> -->
                                    {{ link.name }}
                                </span>
                                <v-tooltip activator="parent" location="bottom">
                                    {{ link.name }}
                                </v-tooltip>
                            </v-list-item-title>
                        </v-list-item>
                        <a
                            target="_blank"
                            href="https://khalmag6.pythonanywhere.com/en/api/about_us/download_file/?file_name=f-gardens.pdf"
                            :class="
                                theme === 'dark' ? 'text-white' : 'text-warning'
                            "
                        >
                            <v-list-item
                                active-class="py-0 mb-1 mt-0"
                                class="my-0 py-0"
                                :append-icon="
                                    lang === 'ar' ? 'mdi-download-box' : ''
                                "
                                :prepend-icon="
                                    lang != 'ar' ? 'mdi-download-box' : ''
                                "
                            >
                                <v-list-item-title class="text-start py-0">
                                    <span class="text-body-2">
                                        PDF
                                        <!-- <v-icon>{{ link.icon }}</v-icon> -->
                                    </span>
                                    <v-tooltip
                                        activator="parent"
                                        location="bottom"
                                    >
                                        {{ $t("download") }}
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item>
                        </a>
                    </v-list>
                </VCol>
                <!-- Quick links End -->

                <!-- Contacts Start -->
                <VCol cols="12" md="3" sm="6" lg="3" xl="4" xxl="4">
                    <div class="mt-5">
                        <h3>{{ $t("pages.contacts") }}</h3>
                    </div>
                    <v-divider class="mx-auto my-3" width="50%"></v-divider>
                    <!-- phone number -->
                    <div ref="animation" class="mb-5 mt-10">
                        <div class="text-start" style="">
                            <a
                                target="_blank"
                                href="tel:00966504547464"
                                :class="
                                    theme == 'dark'
                                        ? 'text-white text-decoration-underline'
                                        : 'text-grey-darken-2 text-decoration-underline'
                                "
                            >
                                <v-tooltip activator="parent" location="bottom">
                                    {{ $t("contacts.phone") }}
                                </v-tooltip>
                                <v-icon class="me-2 my-1" color=""
                                    >mdi-phone-classic</v-icon
                                >

                                <span> {{ $t("contacts.phoneLabel") }} : </span>
                                <span>00966504547464</span>
                            </a>
                        </div>

                        <!-- E-Mail -->
                        <div class="text-start">
                            <a
                                target="_blank"
                                href="mailto:neveen-af@gardens-one.com"
                                :class="
                                    theme == 'dark'
                                        ? 'text-white text-decoration-underline'
                                        : 'text-grey-darken-2 text-decoration-underline'
                                "
                            >
                                <v-tooltip activator="parent" location="bottom">
                                    {{ $t("contacts.email") }}
                                </v-tooltip>
                                <v-icon class="me-2 my-1" color=""
                                    >mdi-email-arrow-right-outline</v-icon
                                >
                                <span> {{ $t("contacts.emailLabel") }} : </span>
                                <span>neveen-af@gardens-one.com</span>
                            </a>
                        </div>
                        <!-- whatsapp -->
                        <div class="text-start">
                            <a
                                target="_blank"
                                aria-label="Chat on WhatsApp"
                                :href="`https://wa.me/+966504547464?text=${encodeURIComponent(
                                    messageWhatsapp
                                )}`"
                                :class="
                                    theme == 'dark'
                                        ? 'text-white text-decoration-underline'
                                        : 'text-grey-darken-2 text-decoration-underline'
                                "
                            >
                                <v-tooltip activator="parent" location="bottom">
                                    {{ $t("contacts.whatsApp") }}
                                </v-tooltip>
                                <v-icon class="me-2 my-1" color=""
                                    >mdi-whatsapp</v-icon
                                >
                                <span
                                    >{{ $t("contacts.whatsAppLabel") }} :
                                </span>
                                <span>00966504547464</span>
                            </a>
                        </div>
                    </div>

                    <div>
                        <a
                            :class="
                                theme == 'dark'
                                    ? 'text-white'
                                    : 'text-grey-darken-2'
                            "
                        >
                            <v-tooltip activator="parent" location="bottom">
                                {{ $t("contacts.address") }}
                            </v-tooltip>
                            <h3 class="text-start">
                                <v-icon>mdi-map-marker-multiple-outline</v-icon>
                                {{ $t("contacts.addressLabel") }}
                            </h3>
                            <address v-if="lang === 'ar'">
                                المملكة العربية السعودية -جدة
                            </address>
                            <address v-else>
                                The Kingdom of Saudi Arabia - Jeddah
                            </address>
                        </a>
                        <VRow class="mt-5">
                            <v-img
                                class=""
                                width="90"
                                height="90"
                                :src="
                                    require('@/assets/imgs/logo/Logo-saudi-vision-2030.png')
                                "
                            ></v-img>
                            <v-img
                                class=""
                                width="120"
                                height="90"
                                :src="
                                    require('@/assets/imgs/logo/Logo-saudi-made.png')
                                "
                            ></v-img>
                        </VRow>
                    </div>
                </VCol>
                <!-- Contacts End -->

                <!-- Start -->
                <VCol cols="12" md="3" sm="6" lg="3" xl="4" xxl="4">
                    <!-- <div class="mt-5">
                        <h3>{{ $t("pages.contacts") }}</h3>
                    </div> -->
                    <!-- <v-divider class="mx-auto my-3" width="50%"></v-divider> -->
                    <v-img
                        :src="
                            require('../../assets//imgs/page-background/footer-image.png')
                        "
                        lazy-src="https://picsum.photos/id/11/100/60"
                        cover
                    >
                        <template v-slot:placeholder>
                            <div
                                class="d-flex align-center justify-center fill-height"
                            >
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    :size="50"
                                ></v-progress-circular>
                            </div>
                        </template>
                    </v-img>
                </VCol>
            </VRow>
        </v-container>
        <VRow
            justify="center"
            align-items="center"
            class="border border-b-0 border-s-0 border-e-0 w-100 mt-3"
        >
            <vCol class="text-center w-100">
                <!-- © Copyright 2024. All Rights Reserved. for F-Gardens developed
                By KHALMAG -->
                <p :class="theme == 'dark' ? '' : 'text-primary'">
                    {{ $t("footer.copyright") }}
                </p>
            </vCol>
        </VRow>
    </VFooter>
</template>
<script>
export default {
    name: "AppFooter",

    data() {
        return {
            theme: localStorage.themePreference,
            lang: localStorage.lang,

            links: [
                {
                    name: this.$t("pages.home"),
                    hover: this.$t("pages.home"),
                    to: "/",
                    icon: "mdi-home",
                },
                {
                    name: this.$t("pages.aboutUs"),
                    hover: this.$t("pages.aboutUs"),
                    to: "/about",
                    icon: "mdi-information-outline",
                },
                // {
                //     name: this.$t("pages.products"),
                //     hover: this.$t("pages.products"),
                //     to: "/products",
                //     icon: "mdi-forest-outline",
                // },
                {
                    name: this.$t("pages.services"),
                    hover: this.$t("pages.services"),
                    to: "/services",
                    icon: "mdi-face-agent",
                },
                {
                    name: this.$t("pages.categories"),
                    hover: this.$t("pages.categories"),
                    to: "/categories",
                    icon: "mdi-shape-plus-outline",
                },
                {
                    name: this.$t("pages.partners"),
                    hover: this.$t("pages.partners"),
                    to: "/partners",
                    icon: "mdi-handshake-outline",
                },
                {
                    name: this.$t("pages.projects"),
                    hover: this.$t("pages.projects"),
                    to: "/projects",
                    icon: "mdi-shovel",
                },
                {
                    name: this.$t("pages.contactUs"),
                    hover: this.$t("pages.contactUs"),
                    to: "/contact-us",
                    icon: "mdi-card-account-phone",
                },
                // {
                //     name: this.$t("download"),
                //     hover: this.$t("download"),
                //     to: "",
                //     icon: "mdi-download-box",
                // },
            ],
        };
    },

    mounted() {},

    methods: {},
};
</script>
<style lang="scss" scoped>
#app
    > div
    > footer
    > div.v-container.v-locale--is-ltr.pa-1
    > div
    > div:nth-child(2)
    > div.v-list.v-theme--light.bg-transparent.v-list--density-default.v-list--one-line
    > a {
    min-height: 30px;
}
a {
    font-size: 9pt;
}
</style>
