<template>
    <v-navigation-drawer
        width="180"
        :color="theme == 'light' ? '#f8f8ffcf' : '#000000a6'"
        v-model="drawer"
        class="px-1 pt-1"
    >
        <VBtn
            @click="
                drawer = false;
                this.$emit('closeDrawer');
            "
            color="error"
            flat
            class="ms-1 d-block mb-2"
            height="20px"
            width="20px"
        >
            <v-icon size="small">mdi-close</v-icon>
        </VBtn>
        <VDivider />
        <v-list>
            <v-list-item v-for="link in links" :key="link" :to="link.to">
                <v-list-item-title class="text-start">
                    {{ link.name }}
                    <v-tooltip activator="parent" location="bottom">
                        {{ link.name }}
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import { ref, watch } from "vue";
export default {
    setup(props) {
        const drawer = ref(props.drawerPass);

        watch(
            () => props.drawerPass,
            (newVal) => {
                drawer.value = newVal;
            }
        );

        return {
            drawer,
        };
    },
    name: "AppAside",
    props: {
        drawerPass: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            theme: localStorage.themePreference,
            links: [
                {
                    name: this.$t("pages.home"),
                    hover: this.$t("pages.home"),
                    to: "/",
                },
                {
                    name: this.$t("pages.aboutUs"),
                    hover: this.$t("pages.aboutUs"),
                    to: "/about",
                },
                // {
                //     name: this.$t("pages.products"),
                //     hover: this.$t("pages.products"),
                //     to: "/products",
                // },
                {
                    name: this.$t("pages.services"),
                    hover: this.$t("pages.services"),
                    to: "/services",
                },
                {
                    name: this.$t("pages.categories"),
                    hover: this.$t("pages.categories"),
                    to: "/categories",
                },
                {
                    name: this.$t("pages.partners"),
                    hover: this.$t("pages.partners"),
                    to: "/partners",
                },
                {
                    name: this.$t("pages.projects"),
                    hover: this.$t("pages.projects"),
                    to: "/projects",
                },
                {
                    name: this.$t("pages.contactUs"),
                    hover: this.$t("pages.contactUs"),
                    to: "/contact-us",
                },
            ],
        };
    },

    mounted() {},

    methods: {},
};
</script>
