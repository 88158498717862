import { createStore } from "vuex";
import lang from "./lang.js";
import theme from "./theme.js";

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        lang,
        theme,
    },
});
