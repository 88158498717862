import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
// import VueMeta from "vue-meta";
// ====== VueIntersect start ======

// ====== VueIntersect end ======

// ============ i18n Start;
import i18n from "@/i18n/i18n.js";
// ====== i18n end ======

import VueScrollReveal from "vue-scroll-reveal";

// ====== VueIntersect end ======

// vuetify
import vuetify from "@/plugins/vuetify";
import "./scss/main.scss";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true;

createApp(App)
    .use(store)
    .use(router)
    .use(VueScrollReveal)
    .use(vuetify)
    .use(ElementPlus)

    .use(i18n)
    .mount("#app");
