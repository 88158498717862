import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "home",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
    },
    {
        path: "/about",
        name: "about",
        meta: {
            title: "About Us",
            metaTags: [
                {
                    name: "description",
                    content: "Description of the about us page",
                },
            ],
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        meta: {
            title: "Contact Us",
            metaTags: [
                {
                    name: "description",
                    content: "Description of the contact us page",
                },
            ],
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/ContactUs.vue"),
    },

    //  Categories Start
    {
        path: "/categories",
        name: "OurCategories",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/OurCategories.vue"
            ),

        meta: {
            title: "Categories",
            metaTags: [
                {
                    name: "description",
                    content: "Description of the Categories",
                },
            ],
        },
    },
    //   Show Category
    {
        path: "/category/:id",
        name: "ShowCategory",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/categories/ShowCategory.vue"
            ),
    },
    //  Categories End

    //  Services Start
    {
        path: "/services",
        name: "OurServices",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/OurServices.vue"),
        meta: {
            title: "Services",
            metaTags: [
                {
                    name: "description",
                    content: "Description of the Services",
                },
            ],
        },
    },
    //   Show Category
    {
        path: "/service/:id",
        name: "ShowService",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/services/ShowService.vue"
            ),
    },
    //  Services End

    //  Products Start
    {
        path: "/products",
        name: "OurProducts",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/OurProducts.vue"),
        meta: {
            title: "Products",
            metaTags: [
                {
                    name: "description",
                    content: "Description of the Products",
                },
            ],
        },
    },
    //   Show Product
    {
        path: "/product/:id",
        name: "ShowProduct",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/products/ShowProduct.vue"
            ),
    },
    //  Products End

    //  partners Start
    {
        path: "/partners",
        name: "OurPartners",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/OurPartners.vue"),
        meta: {
            title: "Our Partners",
            metaTags: [
                {
                    name: "description",
                    content: "Description of the Our Partners",
                },
            ],
        },
    },
    //   Show partner

    {
        path: "/partner/:id",
        name: "PartnerShow",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/partners/PartnerShow.vue"
            ),
    },

    //  Partners End

    //  projects Start
    {
        path: "/projects",
        name: "OurProjects",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/OurProjects.vue"),
        meta: {
            title: "Projects",
            metaTags: [
                {
                    name: "description",
                    content: "Description of the Our Projects",
                },
            ],
        },
    },
    //   Show Project
    {
        path: "/project/:id",
        name: "ShowPartner",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/projects/ShowProject.vue"
            ),
    },
    //  Projects End

    {
        path: "/b-s-card",
        name: "TestComponent",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/TestComponent.vue"
            ),
    },
    {
        path: "/:pathMatch(.*)*", // Catch all unmatched routes
        name: "NotFound",
        component: () =>
            import(/* webpackChunkName: "about" */ "@/views/NotFound"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Global navigation guard
router.beforeEach((to, from, next) => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    next();
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "F.GARDENS";

    // Remove existing meta tags
    const metaTags = document.querySelectorAll('meta[name^="description"]');
    metaTags.forEach((tag) => tag.remove());

    // Add new meta tags
    if (to.meta.metaTags) {
        to.meta.metaTags.forEach((metaTag) => {
            const tag = document.createElement("meta");
            tag.name = metaTag.name;
            tag.content = metaTag.content;
            document.head.appendChild(tag);
            // console.log(`Added meta tag: ${tag.outerHTML}`);
        });
    }

    next();
});

// router.beforeEach(async (to, from, next) => {
//     try {
//         // Make API call to fetch meta information dynamically
//         const response = await fetch("../../public/db.json");
//         const data = await response.json();

//         // Update meta information based on API response
//         if (data && data.meta) {
//             to.meta.title = data.meta.title || "F.GARDENS"; // Default title if not provided
//             to.meta.metaTags = data.meta.metaTags || [
//                 { name: "description", content: "Default description" },
//                 { name: "keywords", content: "default, keywords" },
//                 // Add more default meta tags as needed
//             ];
//         }
//     } catch (error) {
//         console.error("Error fetching meta information:", error);
//     }

//     // Apply meta information
//     document.title = to.meta.title;
//     const metaTags = document.querySelectorAll(
//         'meta[name^="description"], meta[name^="keywords"]'
//     );
//     metaTags.forEach((tag) => tag.remove());
//     if (to.meta.metaTags) {
//         to.meta.metaTags.forEach((metaTag) => {
//             const tag = document.createElement("meta");
//             tag.name = metaTag.name;
//             tag.content = metaTag.content;
//             document.head.appendChild(tag);
//             console.log(`Added meta tag: ${tag.outerHTML}`);
//         });
//     }

//     next();
// });

export default router;
