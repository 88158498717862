import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
// import store from "@/store";

// ======================  get themes list Start ================

// ==============================================
// ========================================== get themes list End ===========================

// store.dispatch("setThemes", ["light", "dark"]);

const vuetify = createVuetify({
    components,
    directives,
    ssr: true,
    theme: {
        // defaultTheme: store.getters.getAppTheme,
        // defaultTheme: light,
        themes: {
            light: {
                colors: {
                    // primary: "#4CAF50", // Change to your desired color
                    primary: "#00695C", // Change to your desired color
                    // primary: "#FFD700",
                    secondary: "#607D8B",
                },
            },
            dark: {
                colors: {
                    // primary: "#4CAF50", // Change to your desired color
                    primary: "#00695C",
                    // primary: "#FFD700",
                    secondary: "#607D8B",
                },
            },
        },
        dark: false,
    },
    icons: {
        defaultSet: "mdi",
    },
});

export default vuetify;
