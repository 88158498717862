<template>
    <v-app-bar
        density="comfortable"
        :order="order"
        flat
        elevation="4"
        class="app-main-nav-bar bg-primary"
    >
        <!--  -->
        <!-- class="d-block d-sm-none" -->
        <v-app-bar-nav-icon
            icon="mdi-dots-vertical"
            variant="text"
            @click.stop="toggleDrawer"
        ></v-app-bar-nav-icon>
        <router-link
            class="mx-3 text-decoration-none text-amber-darken-2"
            color="grey-darken-2"
            to="/"
        >
            <span class="d-flex text-success" style="font-weight: 700">
                <v-img
                    class="d-inline-block mx-0 pa-0 rounded-circle"
                    width="50"
                    rounded
                    :src="require('@/assets/imgs/logo/f-gardens-logo.png')"
                ></v-img>
            </span>
        </router-link>
        <!-- <p v-if="drawer">under contracture</p> -->
        <h2 class="mb-1 text-start d-none d-md-block" style="width: 20%">
            {{ $t("projectName") }}
        </h2>
        <div class="d-none d-sm-block">
            <router-link class="mx-5" to="/">{{
                $t("pages.home")
            }}</router-link>
            <router-link to="/about">{{ $t("pages.about") }}</router-link>
            <router-link class="ms-5" to="/services">{{
                $t("pages.services")
            }}</router-link>
            <!-- <router-link class="ms-5" to="/products">{{
                $t("pages.products")
            }}</router-link> -->
            <router-link class="ms-5" to="/categories">{{
                $t("pages.categories")
            }}</router-link>

            <router-link class="ms-5" to="/contact-us">{{
                $t("pages.contactUs")
            }}</router-link>
            <!-- More Menu Start -->
        </div>
        <div class="text-center d-none d-sm-block">
            <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                    <span
                        class="ms-5 text-grey-lighten-1 more-links"
                        color="primary"
                        v-bind="props"
                    >
                        {{ $t("pages.more") }}...
                    </span>
                </template>

                <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index">
                        <v-list-item :to="item.to" :prepend-icon="item.icon">{{
                            item.title
                        }}</v-list-item>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <template v-slot:append>
            <span class="mx-2">
                <v-icon
                    v-if="darkLightThem == 'light' || darkLightThem == 'dark'"
                    @click="toggleTheme()"
                    >mdi-compare</v-icon
                >
                <v-tooltip activator="parent" location="bottom">
                    {{ $t("toolTips.changeTheme") }}
                </v-tooltip>
            </span>
            <span>
                <!-- arabic ع -->
                <v-img
                    v-show="$store.getters.getAppLang == 'ar'"
                    @click="changeLanguage()"
                    class="rounded-circle mx-0"
                    style="cursor: pointer"
                    :width="20"
                    :height="20"
                    aspect-ratio="16/9"
                    cover
                    :src="require('@/assets/imgs/app-header/us_flag.jpg')"
                    loading="lazy"
                ></v-img>
                <v-tooltip activator="parent" location="bottom">
                    {{ $t("toolTips.changeLanguage") }}
                </v-tooltip>
            </span>
            <!--  English Icon -->
            <span style="width: auto">
                <v-icon
                    v-show="$store.getters.getAppLang == 'en'"
                    @click="changeLanguage()"
                    color="wight"
                >
                    mdi-abjad-arabic
                </v-icon>
                <v-tooltip activator="parent" location="bottom">
                    {{ $t("toolTips.changeLanguage") }}
                </v-tooltip>
            </span>
        </template>
        <!-- More Menu End -->
    </v-app-bar>
</template>
<script>
import store from "@/store";
export default {
    name: "AppHeader",
    props: {
        changeLanguage: {
            type: Function,
        },
        toggleTheme: {
            type: Function,
        },
        toggleDrawer: {
            type: Function,
        },
    },

    data() {
        return {
            darkLightThem: store.getters.getAppTheme,
            // drawer: null,
            items: [
                // {
                //     title: this.$t("pages.categories"),
                //     icon: "mdi-shape",
                //     to: "/Categories",
                // },
                // {
                //     title: this.$t("pages.products"),
                //     icon: "mdi-flower-outline",
                //     to: "products",
                // },
                {
                    title: this.$t("pages.partners"),
                    icon: "mdi-flower-tulip",
                    to: "/partners",
                },
                {
                    title: this.$t("pages.projects"),
                    icon: "mdi-shovel",
                    to: "/projects",
                },
            ],
        };
    },
    watch: {
        "$store.getters.getAppTheme"(newValue) {
            // console.log("darkLightThem changed to:", newValue);
            this.darkLightThem = newValue;
            // You can perform any actions here based on the new value
        },
    },

    mounted() {},

    methods: {
        toggleAsideMobile() {
            this.$emit("toggleAsideMobile");
        },
    },
};
</script>

<style lang="scss">
.more-links:hover {
    color: white !important;
    cursor: pointer;
}
</style>
