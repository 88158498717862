const state = {
    lang: localStorage.getItem("lang") || "en",
};
const mutations = {
    SET_LANG(state, lang) {
        state.lang = lang;
        localStorage.setItem("lang", lang);
        window.location.reload();
    },
};

const actions = {};
const getters = {
    getAppLang: (state) => state.lang,
};
export default {
    state,
    mutations,
    actions,
    getters,
};
