<template>
    <VApp id="app">
        <v-layout
            :style="appLang == 'ar' ? 'direction:rtl' : 'direction:ltr'"
            class="rounded rounded-md"
        >
            <AddHeader
                class="position-fixed"
                :style="appLang == 'ar' ? 'direction:rtl' : 'direction:ltr'"
                :changeLanguage="changeLanguage"
                :toggleTheme="toggleTheme"
                :drawer="drawer"
                :toggleDrawer="toggleDrawer"
            />
            <AppAside
                @closeDrawer="closeDrawer"
                class="position-fixed"
                v-if="drawer"
                :drawerPass="drawer"
                style="z-index: 999999999999"
                :location="lang == 'ar' ? 'right' : 'left'"
            />

            <v-main
                class="d-flex align-center justify-center w-100 mt-0 pt-12"
                style="min-height: 300px"
            >
                <router-view class="" />
                <!-- <transition name="slide" mode="in-out"> </transition> -->
            </v-main>
        </v-layout>
        <appFooter v-if="!hideFooter" />
        <!-- <VFooter height="90" color="primary">
            <VRow>
                <div class="text-center w-100">
                    © Copyright 2024. All Rights Reserved. for F-Gardens
                    developed By KHALMAG
                </div>
            </VRow>
        </VFooter> -->
    </VApp>
</template>

<script>
export default {
    name: "App",

    data() {
        return {
            theme: localStorage.themePreference,
            // drawer: false,
            route: this.$route,
        };
    },

    mounted() {},
    computed: {
        hideFooter() {
            // Replace '/contact-list' with the route path where you want to hide the footer
            return this.$route.path === "/b-s-card";
        },
    },

    methods: {
        // toggleDrawer() {
        //     this.drawer = !this.drawer;
        // },
    },
};
</script>

<script setup>
import AddHeader from "@/components/app-header/AppHeader.vue";
import AppAside from "@/components/main-app-components/AppAside.vue";
import AppFooter from "@/components/main-app-components/AppFooter.vue";
import { ref, watch, onMounted } from "vue";
import store from "@/store";
import { useTheme } from "vuetify";
// import ContactUsComponent from "@/components/about-components/ContactsComponent.vue";

const lang = ref(store.getters.getAppLang);
const appLang = ref(lang.value);
const myTheme = ref(store.getters.getAppTheme);
const theme = useTheme();

const drawer = ref(false);

const toggleDrawer = () => {
    drawer.value = !drawer.value;
};
const closeDrawer = () => {
    drawer.value = false;
};

// const drawer = ref(false);

const changeLanguage = () => {
    if (store.getters.getAppLang == "ar") {
        store.commit("SET_LANG", "en");
    } else {
        store.commit("SET_LANG", "ar");
    }

    watch(
        () => store.getters.getAppLang,
        (newLang) => {
            appLang.value = newLang;
        }
    );
};

function toggleTheme() {
    const newTheme = theme.global.current.value.dark ? "light" : "dark";
    theme.global.name.value = newTheme;
    localStorage.setItem("themePreference", newTheme);

    // console.log(theme.global.current.value.dark, theme.global.name.value);
    window.location.reload();

    // const val = theme.global.current.value;
    // console.log(`Current theme is dark? ${JSON.stringify(val)}`);
    // localStorage.setItem("val", JSON.stringify(val));
}
function loadThemePreference() {
    const savedTheme = localStorage.getItem("themePreference");
    if (savedTheme) {
        theme.global.name.value = savedTheme;
    }
}
onMounted(async () => {
    loadThemePreference();
    if (
        !localStorage.getItem("theme") ||
        !localStorage.getItem("themePreference")
    ) {
        localStorage.setItem("theme", store.getters.getAppTheme);
        localStorage.setItem("themePreference", store.getters.getAppTheme);
    }
});

watch(
    () => store.getters.getAppTheme,
    (newTheme) => {
        myTheme.value = newTheme;
        theme.global.name.value = newTheme; // Assuming Vuetify uses this for theme switching
        localStorage.setItem("theme", newTheme); // Update local storage for theme
    }
);
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: monospace;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    color: #2c3e50;
    // color: #ffffff;
    min-height: 100vh;
}

a {
    font-weight: bold;
    color: #2c3e50;
    color: #bdbdbd;
    text-decoration: none;

    &.router-link-exact-active {
        color: #42b983;
        color: #ffffff;
    }
}
nav {
    padding: 30px;
}
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
//#endregion

.slide-enter-active {
    animation: transitionSliderIn 0.5s ease-in-out;
}
.slide-leave-active {
    animation: transitionSliderOut 0.5s ease-in-out;
}
@keyframes transitionSliderIn {
    from {
        opacity: 0;
        transform: translate(-5%);
    }
    to {
        opacity: 1;
        // transform: translate(0%);
    }
}
@keyframes transitionSliderOut {
    from {
        opacity: 1;
        // transform: translate(0%);
    }
    to {
        opacity: 0;
        // transform: translate(-20%);
    }
}

// =====

// Custom scrollbar styles
::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888888; /* color of the scroll thumb */
    background: #016358a7;
    border-radius: 0px; /* roundness of the scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scroll thumb on hover */
    background: #016358ec;
}
</style>
// access token developer api
//EAAGC9daqWnMBO4L8CZC5bGZB520l70LAxbf6yrbh6H1cJwjT6qwT9KPYq2VJVQtamVRF6pO5KJAEnUbNUsjF2bZCx5mnP8YZCgorQ3PMHYRuOs7V8VOn9tozRIJXHX5cItovcJhDNkGWJwMTMrZBWtWjHvIobFGjoA8h5k3o9HFZAZBuw6O3vVeY9TArARpzZBaoqLEpZBcAtycxytioIHm2pmTjJgzP9gYHLxF5QncMZBJMGpfvcPQ4e1WNikfpKhndwhtrgiMboZD
